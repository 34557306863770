#main p,h2,h3 {
    max-width: 38rem;
    hyphens:auto;
}
#main .no-hyphens p,h2,h3 {
    hyphens:none;
}
blockquote {font-style: italic;font-stretch:extra-condensed;max-width:34rem;}
ul {max-width: 36rem;}
h1, h2{
    text-transform: uppercase;
    letter-spacing: .1ex;
}
.mod_newsreader h2{font-size:1.6rem;font-weight:bolder;letter-spacing: normal;text-transform: none;}

p {line-height: 1.6;}

#logo {
    font-size:2rem;
    font-weight:bold;
    letter-spacing: .05ex;
    line-height: 4rem;
}
#company {padding-left:.5rem;}
#header a {text-decoration: none;color:inherit;}
small,.small  {font-size:.8em;}
.small {line-height:1.2em;}
.small p, .small h2 {margin-bottom: .3em;margin-top:.3em;}
.small ul {margin-top: .0em;}
