@media (max-width: 600px) {
    .big img {
        max-width: 100vw;
        margin: 0 -2rem;
    }
}

@media (max-width: 490px) {
    figure, h2, h3 {
        width: 100%;
        text-align: center;

    }
}