nav > ul {
    display: flex;
}



nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: .1em;
}
#header nav ul { justify-content: flex-end;}
#header nav ul ul {
    position: absolute;
    /*background: var(--color-bg-default);*/
    box-shadow: .3em .4em .4em rgba(0, 0, 0, .4);
    padding: .3em 1em;
    margin: 0;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-2em);
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
}

#header #hamburger:checked ~ nav ul ul {
    max-height: 500vh;
    position: relative;
    left: 0;
    right: 0;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    box-shadow: none;
    overflow: visible;
    background-color: var(--color-bg-default);
}




#header nav li:focus ul,
#header nav li:hover ul {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
}

nav a,
nav strong {
    text-decoration: none;
    color: inherit;
    display: block;
    /*width: 100%;*/
    padding: .3rem;
    margin: .2rem 0;
}

#header nav > ul > li,
#footer nav > ul > li{
    padding: .3rem;
    margin-top: 1.3rem;
    /*border-bottom:1px grey solid;*/
}

nav a.trail,
nav strong.active {
    color: var(--color-bg-dark);
}


header nav {
    transition: max-height .3s ease-in-out 0s;
    max-height: 0;
    overflow: hidden;
    z-index: 1;
}

header p {
    margin-top: 0;
}

header label.mobile {
    cursor: pointer;
    z-index:2;
    position: absolute;
    right: 0;
}

#header #hamburger:checked ~ nav {
    max-height: 500vh;
    position: absolute;
    left: 0;
    right: 0;
    overflow: visible;
    background-color: var(--color-bg-default);
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

nav a,
nav strong {
    text-decoration: none;
    color: inherit;
    display: block;
    /*width: 100%;*/
    padding: .3rem;
    margin: .2rem 0;
}

#header nav > ul > li {

    text-transform: uppercase;
    letter-spacing: .1em;
    text-align: left;
    padding: .3rem;
    margin-top: 1.3rem;
    border-bottom: 1px grey solid;
}



#header nav > ul {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
}

#header nav ul ul {
    text-transform: initial;
    z-index: 1;
}

li.submenu > a::after, li.submenu > strong::after {
    content: ' ▼ ';
    display: inline-block;
    transition: all .4s;
    font-size: .6em;
    padding-left: .4em;
}

li.submenu:hover > a::after, li.submenu:hover > strong::after {
    transform: rotate(-180deg);
}



@media all and (min-width: 1000px) {
    .mobile {
        display: none;
    }

    #header nav {
        display: block;
        max-height: unset;
        overflow: unset;
    }

    li:focus > ul,
    li.submenu ul.level_2,
    li.submenu:hover ul.level_2,
    li.submenu:focus ul.level_2,
    li.submenu ul.level_2:focus,
    li.submenu ul.level_2:hover {
        visibility: visible;
        opacity: 1;
        transform: translateY(0%);
        transition-delay: 0s, 0s, 0.3s;

    }

    #header nav > ul {
        flex-direction: row;
    }

    li.submenu ul.level_2 {
        visibility: hidden;
        opacity: 0;
        transform: translateY(-2em);
        transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    }

    #header nav ul ul {
        position: absolute;
        background: rgba(256, 256, 256, 1);
        box-shadow: .3em .4em .4em rgba(0, 0, 0, .4);
        padding: .3em 1em;
        margin: 0;
    }

    #header nav ul li {
        border-bottom: unset;
        padding-bottom: unset;
        margin-top: unset;
    }
}


label.mobile .hamburger {
    margin-top: 1rem;
    height: 2rem;
    line-height: 2rem;
    display: block;
    position: absolute;
    left: calc(100% - 4rem);

}

.line {
    position: absolute;
    left: 5px;
    height: 4px;
    width: 2rem;
    background: black;
    display: inline-block;
    transition: 0.5s;
    transform-origin: center;
}

.line:nth-child(1) {
    top: .5rem;
}

.line:nth-child(2) {
    top: 1rem;
}

.line:nth-child(3) {
    top: 1.5rem;
}

#hamburger:checked + .mobile .hamburger .line:nth-child(1) {
    transform: translateY(.5rem) rotate(-45deg);
}

#hamburger:checked + .mobile .hamburger .line:nth-child(2) {
    opacity: 0;
}

#hamburger:checked + .mobile .hamburger .line:nth-child(3) {
    transform: translateY(-.5rem) rotate(45deg);
}
