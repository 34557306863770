.content-slider {
    width: 1000px;
    max-width: 100vw;
}
.slider-control {
    height:6rem;
}
.slider-control .slider-menu {
    margin-top:3rem;
    font-size: 1em;
}

.slider-control .slider-menu b {
    padding: .3rem;
    color: var(--color-action);
}

.slider-control .slider-menu b.active {
    color: var(--color-bg-dark);
}

.slider-control a {
    display: none;
    margin-top:-20rem;
    font-size:3rem;
    color: var(--color-action);
}
.ce_sliderStart:hover .slider-control a{
    display:block;
}
.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.content-slider .image-container {
    display: flex;
    align-items: center;
    width: 30rem;
    height: 30rem;

}

.content-slider .text-container {
    padding: 0 2rem;
    max-width: 30rem;
}

.text-container {
    height: 30rem;
    overflow-y: auto;
}

.text-container p {
    font-weight: lighter;
    font-size: .9em;
}

@media (max-width: 60rem) {
    .slider-control a {
        margin-top:-32rem;
    }
}