input:not([type="checkbox"]), textarea, select {
    box-shadow: inset 0 .0625em .125em rgba(10, 10, 10, .05);
    max-width: 100%;
    width: 100%;
    padding: calc(.75em - 1px);
    height: auto;
    font-size: .8em;
    border-radius: .3ex;
    border: 1px solid lightgrey;
    transition: border-color .8s;
}

input:focus, textarea:focus, select:focus {
    border-bottom-color: var(--color-bg-primary);
    border-bottom-width:2px;
}

textarea {
    display: block;
    max-width: 100%;
    min-width: 100%;
    resize: vertical;
}

button {
    font-size: 1em;
    border-radius: 4px;
    border: transparent;
    display: inline-block;
    padding: 1em 2em;
    background-color: var(--color-bg-primary);
    color: var(--color-bg-default);
    text-decoration: none;
}
.widget-submit{text-align:center;}
.select select {
    cursor: pointer;
    display: block;
    max-width: 100%;
    outline: 0;
}

select:not([multiple]) {
    padding-right: 2.5em;
}

label, legend {
    display: block;
    font-weight: bold;
}

.widget-checkbox label {
    display: inline;
    font-weight: normal;
}
.checkbox{
    margin:1ex 1em 0 0;
}
fieldset {
    display: flex;
    flex-wrap:wrap;
}

fieldset span {
    margin: 1ex 0;
    display: flex;
    min-width:320px;
    align-items: flex-start;
}

span.mandatory {
    display: inline-block;
    padding: 0 0 0 .5ex;
    color: var(--color-danger);

}

.ce_form {
    max-width: 650px;
}

.flex > .formbody {
    max-width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.widget {
    width:100%;
    padding: 1em;
}

.widget.w-half {
    width: 48%;

}

@media (max-width: 600px) {
    .flex > .formbody {
        flex-direction: column;
        justify-content: space-around;
    }

    .widget.w-half {
        width: 100%;
    }
}