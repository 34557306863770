figuure {
    position: relative;
}

figcaption {
    position: relative;
    background: var(--color-bg-primary);
    opacity: 90%;
    bottom: 2em;
    left: 1em;
    padding: 1ex 1em;
    font-style: oblique;
    font-size: smaller;
    display: table-cell;
    color: white;
    /*mix-blend-mode: soft-light;*/
}

figcaption > span {
    font-size: .8em;

    display: block;
}

figcaption > span > a {
    color: inherit;
}