@import "vars.css";
@import "./base.css";
@import "layout.css";
@import "typo.css";
@import "responsive.css";
@import "modules/navigation.css";
@import "modules/article.css";
@import "modules/news.css";
@import "modules/slider.css";
@import "modules/forms.css";
@import "modules/page-intro.css";
@import "modules/picture.css";


body {
    min-height: 101vh;
    color: rgba(80, 80, 82, 1);
    font-size: calc(18px + (21  - 18) * ((100vw - 360px) / (1400 - 360)));
    font-family: var(--font-sans-serif);
}

body, #header, nav ul ul {
    /*background: url("../images/papier.png") var(--color-bg-default);*/
}
p a, ul a{
    text-decoration: none;
    color: var(--color-action);
    display:inline-block;
}
 p a:after, ul a:after{
     content: '';
     display: block;
     width: 0;
     height: .3ex;
     background: var(--color-action);
     transition: all .3s;
     text-align: center;
     position: relative;
     left:50%;
 }
p  a:hover:after, ul a:hover:after{
     width:100%;
     left:0;
 }

#header {
    z-index:2;
    background-image: linear-gradient(to top, rgba(255, 255, 255, .1), rgba(255, 255, 255, .3),rgba(255, 255, 255, .9), rgba(255, 255, 255, 1)); /* Standard syntax (must be last) */
     /*background-color: rgba(255, 255, 255, .9);*/
}
#logo {display:flex;}
#logo span{display:inline-block;padding-left:1em;font-variant: small-caps;}
#logo svg {
    float: left;
    height: 4rem;
}
#logo svg g path:nth-child(4),
#logo svg g path:nth-child(3){
    transform-origin: center center;
    transform: rotate(0deg);
    transition: transform 2s ease-out;
}

#logo:hover svg g path:nth-child(4),
#logo:hover svg g path:nth-child(3){
    transform-origin: center center;
    transform: rotate(180deg);
    transition: transform 2.2s ease-in;
}

.bg-dark {
    background-color: var(--color-bg-dark);
    color: var(--color-bg-default);
}
.accented{
    background-color: var(--color-bg-primary);
    color: var(--color-bg-default);
    /*padding: 1rem 3rem;*/
}

a.button {
    display:inline-block;
    padding:1em 2em;
    background-color: var(--color-action);
    color: var(--color-bg-default);
    text-decoration: none;
    margin:1.6rem;

}

footer .row { padding:1.6rem; }