* {
    box-sizing: border-box;
}

.invisible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

a[href*="#skip"].invisible:focus {
    display: inline-block;
    clip: auto;
    outline: 1px dotted black;
    width: auto;
    height: auto;
    margin: 0;
    position: relative;
}

body, form, figure {
    margin: 0;
    padding: 0
}

img {
    border: 0;
    max-width: 100%;
    height: auto;
}

header, footer, nav, section, aside, article, figure, figcaption, div,main {
    display: block;
}


.float_right {
    float: right;
    margin-left: 1rem;
}

.float_left {
    float: left;
    margin-right: 1em;
}