#header {
    top:0;
    left:0;
    right:0;
    position: fixed;
    z-index: 1;
    display:flex;
    justify-content: space-between;
    height:6rem;

    /*box-shadow: .0em .4em .4em rgba(0,0,0,.1);*/
}
#container {
    display:flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    margin:auto;
    margin: 6rem auto 0;
    min-height: calc(100vh - 12rem);
}

main {
    flex-basis:62%;
    flex-grow: 1;
    display:flex;
    justify-content: center;
    flex-wrap:wrap;


}
aside {
    min-width: 15rem;
    flex-basis:38%;
    background:var(--color-bg-primary);
    color: rgba(240,240,240, 0.9);
    flex-grow:1;
}
footer {
    display: flex;
    justify-content: center;
    flex-wrap:wrap;
    padding:2em;
}

.row {
    width:100vw;
    flex-shrink:0;
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    margin-bottom: 3rem;

}
.claim {
    width:100vw;
    flex-shrink:0;
}
.flex{
    display:flex;
    flex-wrap:wrap;

}
