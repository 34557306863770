:root {
    --color-bg-default: rgba(255, 255, 255, 1);
    --color-action: rgba(255, 121, 0, 1);
    --color-bg-primary: rgba(0, 142, 176, 1);
    --color-bg-dark: rgba(14, 46, 65, 1);
    --color-danger: rgba(240, 20, 20, 1);


/*    --color-action: rgba(0, 142, 176, 1);

    --color-bg-dark: #C4940A;*/

/*    --color-action: #D9042B;

    --color-bg-dark: #0A668C;
    --color-bg-primary: #1AA2D9;
    --color-bg-primary: #D9CF1A;

    --color-bg-dark: #617999;
    --color-bg-primary: #86aee1;


    --color-action: #A60321;*/
    /*font-family: Impact, Haettenschweiler, "Franklin Gothic Bold", Charcoal, "Helvetica Inserat", "Bitstream Vera Sans Bold", "Arial Black", sans-serif;*/
    --font-sans-serif: Frutiger, "Frutiger Linotype", Univers, Calibri, "Gill Sans", "Gill Sans MT", "Myriad Pro", Myriad, "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif;
    --font-sans-serif: Corbel, "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans", Verdana, "Verdana Ref", sans-serif;
}
