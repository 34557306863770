.page-intro {
    display: flex;
    justify-content: center;
}

.page-intro-image {
    flex-shrink: 1;

}

.page-intro-text {
    z-index:1;
    order: 1;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    flex-shrink: 1;



}

.page-intro-text h1 {

    font-family: Cambria, "Hoefler Text", Utopia, "Liberation Serif", "Nimbus Roman No9 L Regular", Times, "Times New Roman", serif;
    font-family: Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
    text-transform: none;
    letter-spacing: normal;
    hyphens: auto;
    margin-left: -5rem;
    margin-top: 1em;
}

.page-intro-text h1, .page-intro-text p {
    background: rgba(255, 255, 255, 1);
    padding-left: 1rem;
    padding-right: 1rem;
}

.page-intro-text p {
    font-size: 1.8rem;
    line-height: 1.5;
    max-width: 34rem;
    margin-top: 0;

    /*font-weight: lighter;*/
}
@media (max-width: 1000px) {
    .page-intro{
        flex-direction: column;
        flex-wrap:nowrap;
    }
    .page-intro-text h1 {

        margin:-1em .5em .5em .5em;
    }
    .page-intro-text p {
        padding-left: .5em;
        max-width:unset;
        /*padding-right: .5em;*/
    }
}